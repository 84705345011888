<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">GOLF TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
        <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />

        <div class="content">
          <h2>Golf Travel Insurance</h2>
          <p class="mb-4">
            Whether you are planning a one-off visit the greens on holiday, or
            your main purpose of being away is to golf, then you may want to
            consider having the right travel insurance in place, to cover you
            and your clubs while you are abroad.
          </p>
          <p class="mb-4">
            Our golf travel insurance will cover your green fees if you have bad
            weather and are unable to play, as well as cover if your golf
            equipment is lost, stolen or damaged.
          </p>
          <p class="mb-4">
            Spain, Portugal or USA, whichever golfing destination you are headed
            to, it is important to have the right travel insurance in place for
            your trip.
          </p>

          <b-collapse
            class="card"
            v-for="faq in faqs"
            :key="faq.id"
            :open="false"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <p class="card-header-title" v-html="faq.title" />
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
              </a>
            </div>
            <div class="card-content">
              <div class="content" v-html="faq.content" />
            </div>
          </b-collapse>
        </div>

        <p class="mb-4">
          <router-link :to="{ name: 'getQuote' }"
            >Get a quote for golf travel insurance today.</router-link
          >
        </p>

        <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
        <info-boxes />
        <!-- <trustpilot-banner /> -->
      </div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from '../components/common/InfoBoxes';
//import TrustpilotBanner from '@/components/common/trustpilot/TrustpilotBanner';
import InfoButtonOptions from '@/components/common/InfoButtonOptions';

export default {
  name: 'GolfTravelInsurance',
  data() {
    return {
      faqs: [
        {
          id: 1,
          title:
            'Can golf travel insurance be added to a single trip and annual multi-trip policy?',
          content:
            'Yes, our golf cover can be added to both single trips and annual multi-trip policies. ',
        },
        {
          id: 2,
          title: 'Will your policies cover me to play golf?',
          content: `<p>Yes, our policies will cover you to play golf as standard.</p> 
						<p>However, additional golf cover provides extra benefits, such as:</p>
						<ul>
							<li>Cover if your personal golf equipment is lost, stolen or damaged.</li>
							<li>Cover if your hired golf equipment is lost, stolen or damaged.</li>
							<li>Cover if your golf equipment is delayed.</li>
							<li>Cover if you are unable to play due to adverse weather conditions.</li>
							<li>Cover for costs incurred due to you achieving a hole in one.</li>
						</ul>
						<p>The level of cover is outlined in our <a href="https://covered2go.co.uk/policy-wordings-mul">Policy Wordings</a>, as the limits do vary across our three tiers of cover, Silver, Gold and Platinum. </p>`,
        },
        {
          id: 3,
          title: 'What does golf cover include?',
          content: `
						<ul>
							<li>Cover of up to £2,500 if your personal or hired golf equipment is lost, stolen or damaged.</li>
							<li>Cover of up to £200 if your golf equipment is delayed for 12 hours or more.</li>
							<li>Cover of up to £400 if you are unable to play golf due to bad weather.</li>
							<li>Up to £250 to cover the costs if you achieve a hole in one.</li>
						</ul>
						<p>Please read our <a href="https://covered2go.co.uk/policy-wordings-mul">Policy Wordings</a> for full terms, conditions and exclusions. </p>`,
        },
        {
          id: 4,
          title: 'Does golf insurance cover injuries?',
          content: `
						<p>Our policies will cover you for accident and injury whilst playing golf, as standard. You would not need to purchase additional golf cover for this protection. Golf is considered a ‘low risk’ sport, and as such, is covered as standard. </p>
						<p>If you have any <a href="https://covered2go.co.uk/pre-existing-medical-conditions">pre-existing medical conditions</a>, then you will need to disclose these to us, before you travel. </p>`,
        },
        {
          id: 5,
          title:
            'Is there anything else I should consider when buying golf travel insurance?',
          content: `
						<p>If you are planning a golfing holiday with a group of people, then it may be worth seeing if a group policy makes more sense, rather than all buying individual travel insurance policies. </p>
						<p>If you are taking part in any other activities, it is important to check whether these are considered ‘low risk’, like golf, and is covered as standard, or if you need to pay an additional premium to cover any riskier activities. </p>
						<p>We provide cover for many travel-related mishaps, such as, cancellation, curtailment, and delays, however, it is important to check the policy wording to see exactly what we can and cannot cover you for. </p>`,
        },
        {
          id: 6,
          title: 'Do I need to disclose my pre-existing medical conditions?',
          content: `
						<p>Yes. It is very important to disclose your pre-existing medical conditions to ensure you have full cover in place for your trip. While this may mean a slightly higher premium, not declaring your conditions may invalidate your entire policy. </p>`,
        },
      ],
    };
  },
  mixins: [],
  components: {
    InfoBoxes,
    //TrustpilotBanner,
    InfoButtonOptions,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
</style>
